/* eslint-disable object-curly-newline */
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import LogoutIcon from '@mui/icons-material/Logout';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Cookies from 'js-cookie';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';

import postRevokeRefreshToken from '../../../api/private/users/posts/postRevokeRefreshToken';
import postRevokeToken from '../../../api/private/users/posts/postRevokeToken';
import Logo from '../../../assets/PrismIcon.png';
import isProduction from '../../../helper/IsProduction';
import { getLocalStorage, setLocalStorage } from '../../../helper/LocalStorage';
import { getSessionStorage, removeSessionStorage, setSessionStorage } from '../../../helper/SessionStorage';
import { purgeUser } from '../../../redux/user/User';
import InitialSidebarOptions from '../menu/InitialSidebarOptions';

function LogoutButton() {
  const dispatch = useDispatch();

  const handleLogout = async () => {
    // eslint-disable-next-line no-console
    console.info('Logging Out User');
    await postRevokeRefreshToken(getLocalStorage('RefreshToken'));
    await postRevokeToken(Cookies.get('AccessToken'));
    await dispatch(purgeUser());
    if (getLocalStorage('logout-event') !== undefined) {
      setLocalStorage('logout-event', `logout${Math.random()}`);
    }
    removeSessionStorage('custom-background-sidebar');
    removeSessionStorage('hasRunLoginLogic');
    window.location.href = `${window.location.protocol}//${window.location.host}/login`;
  };

  return (
    <ListItemButton
      sx={{ '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.2)' } }}
      onClick={() => handleLogout()}
      className="flex-col"
    >
      <ListItemIcon className="justify-center text-white">
        <LogoutIcon className="w-5" />
      </ListItemIcon>
      <ListItemText
        primary="Logout"
        disableTypography
        className="text-center text-[12px] text-white font-gothammedium"
      />
    </ListItemButton>
  );
}

function IsLinkButton({ link, children }) {
  const siteCode = useSelector((state) => state?.user?.loginResponse?.selectedSite?.SiteCode);

  if (link === '#') {
    return children;
  }

  if (link.startsWith('http://') || link.startsWith('https://')) {
    return (
      <a href={link} target="_blank" rel="noopener noreferrer">
        {children}
      </a>
    );
  }
  return (
    <Link to={{ pathname: link, search: `siteCode=${siteCode}` }}>
      {children}
    </Link>
  );
}

function RenderButtons({
  id,
  link,
  name,
  icon,
  onClick,
}) {
  const { pathname } = useLocation();
  const user = useSelector((state) => state.user.userProfile);

  const isUserAdmin = user?.user_right === 'Admin';
  const isTester = user?.user_right === 'QATeam';
  const isUserDeveloperOrAppManager = user?.user_right === 'Developer' || user?.user_right === 'AppManager';
  const allowProductInfo = user?.user_right === 'Admin' || user?.user_right === 'AppManager';

  if (!isUserAdmin && (id === 'users')) return null;
  if ((!isUserDeveloperOrAppManager && !isUserAdmin && !isTester) && id === 'bulk-operations') return null;
  if ((!allowProductInfo && !isTester) && id === 'product-info') return null;
  if (!isUserAdmin && (id === 'margin-threshold')) return null;

  return (
    <IsLinkButton link={link}>
      <ListItemButton
        key={name}
        sx={{
          '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.2)',
          },
          '&.Mui-selected': {
            backgroundColor: 'rgba(0, 0, 0, 0.3)',
            '&:hover': {
              backgroundColor: 'rgba(0, 0, 0, 0.4)',
            },
          },
        }}
        selected={pathname === '/' ? id === 'dashboard' : pathname.includes(id)}
        onClick={() => onClick()}
        className="relative flex-col group"
      >
        <ListItemIcon className={`justify-center text-white transition-all ${link === '#' ? 'group-hover:-translate-x-4' : ''}`}>
          {icon}
        </ListItemIcon>
        <ListItemText
          primary={name}
          disableTypography
          className={`text-center text-[12px] text-white font-gothammedium transition-all ${link === '#' ? 'group-hover:-translate-x-4' : ''}`}
        />
        {link === '#' && (
          <ListItemIcon className="absolute justify-center text-white transition-all opacity-0 -right-2 top-8 group-hover:opacity-100">
            <ChevronRightRoundedIcon />
          </ListItemIcon>
        )}
      </ListItemButton>
    </IsLinkButton>
  );
}

function ResponsiveDrawer({ children, sidebarWidth, openDrawer, closeDrawer, isProd }) {
  const [backgroundColor, setBackgroundColor] = React.useState('bg-tertiary-600');

  React.useEffect(() => {
    const currentURL = window.location.href;
    if (isProd) {
      setBackgroundColor('bg-tertiary-600');
    } else if (currentURL.includes('dev.prism.jdplc.com')) {
      const lsBackground = getSessionStorage('custom-background-sidebar');
      const customBackground = lsBackground || 'bg-red-900';
      setBackgroundColor(customBackground);
    } else {
      const lsBackground = getSessionStorage('custom-background-sidebar');
      const customBackground = lsBackground || 'bg-yellow-600';
      setBackgroundColor(customBackground);
    }
  }, []);

  return (
    <>
      {/* Desktop Drawer */}
      <Drawer
        variant="permanent"
        sx={{
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: sidebarWidth, overflow: 'hidden' },
          display: { md: 'block', sm: 'none', xs: 'none' },
        }}
        open
        classes={{ paper: backgroundColor }}
      >
        {children}
      </Drawer>
      {/* Mobile Drawer */}
      <Drawer
        variant="temporary"
        open={openDrawer}
        onClose={closeDrawer}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: sidebarWidth },
          display: { md: 'none', sm: 'block', xs: 'block' },
        }}
        classes={{ paper: 'bg-tertiary-600' }}
      >
        {children}
      </Drawer>
    </>
  );
}

function BackgroundColorSelect() {
  return (
    <div
      className={`
        absolute top-[4px] left-[24px]
        z-10
        flex flex-col justify-evenly items-center gap-4
        p-2
        bg-white
        rounded-full
        transition-all duration-500 ease-in-out
        h-0 group-hover:h-[150px]
        opacity-0 group-hover:opacity-100
      `}
    >
      <div className="w-10 h-10 bg-white rounded-full" />
      <button
        type="button"
        className="w-6 h-6 bg-red-900 border rounded-full opacity-0 group-hover:opacity-100 border-red-950"
        onClick={() => {
          setSessionStorage('custom-background-sidebar', 'bg-red-900');
          window.location.reload();
        }}
      >
        {' '}
      </button>
      <button
        type="button"
        className="w-6 h-6 border rounded-full opacity-0 group-hover:opacity-100 bg-yellow-600 border-yellow-600"
        onClick={() => {
          setSessionStorage('custom-background-sidebar', 'bg-yellow-600');
          window.location.reload();
        }}
      >
        {' '}
      </button>
      <button
        type="button"
        className="w-6 h-6 border rounded-full opacity-0 group-hover:opacity-100 bg-tertiary-600 border-tertiary-800"
        onClick={() => {
          setSessionStorage('custom-background-sidebar', 'bg-tertiary-600');
          window.location.reload();
        }}
      >
        {' '}
      </button>
    </div>
  );
}

export default function DesktopDrawer({ sidebarWidth, handleItemClick, openDrawer, closeDrawer }) {
  const user = useSelector((state) => state.user);
  const selectedSite = useSelector((state) => state.user?.loginResponse?.selectedSite);

  const isProd = isProduction();
  const isAdmin = user?.userProfile?.user_right === 'Admin';
  const allowProductInfo = user?.userProfile?.user_right === 'Admin' || user?.userProfile?.user_right === 'AppManager' || user?.userProfile?.user_right === 'QATeam';
  const uatStyles = 'transition-transform group-hover:rotate-180 z-20 duration-500';

  return (
    <ResponsiveDrawer sidebarWidth={sidebarWidth} openDrawer={openDrawer} closeDrawer={closeDrawer} isProd={isProd}>
      <div className="relative flex items-center justify-center min-h-max group">
        <img className={`w-10 my-[17px] ${!isProd && isAdmin ? uatStyles : ''}`} src={Logo} alt="PRISM" />
        {!isProd && isAdmin ? <BackgroundColorSelect /> : null}
      </div>
      <Divider />
      <List sx={{ height: '100%', position: 'relative' }}>
        {InitialSidebarOptions.filter((option) => {
          if (selectedSite?.SiteCode === 'OC' && user?.userProfile?.user_right !== 'ImageChecker') {
            return ['bulk-operations', 'dashboard', 'reports', 'users', 'styles', 'release-notes'].includes(option.id);
          }
          if (selectedSite?.SiteCode === 'JQ' && user?.userProfile?.user_right !== 'ImageChecker') {
            return ['dashboard', 'settings', 'marginThreshold'].includes(option.id) || option.id === 'logout';
          }
          if (user?.userProfile?.user_right === 'ImageChecker') {
            return ['dashboard'].includes(option.id) || option.id === 'logout';
          }
          if (option.id === 'marginThreshold') {
            return false;
          }
          // Display all options for other sitecodes
          return true;
        }).map(({ id, link, name, icon }, index) => (
          <RenderButtons
            key={id}
            id={id}
            link={link}
            name={name}
            icon={icon}
            onClick={() => handleItemClick({ id, link, name, icon }, index)}
          />
        ))}
      </List>
      <Divider />
      {allowProductInfo}
      <Divider />
      <LogoutButton />
    </ResponsiveDrawer>
  );
}
