/* eslint-disable no-nested-ternary */
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { FormControl, InputLabel, Select } from '@mui/material';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import useMediaQuery from '@mui/material/useMediaQuery';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Link, useLocation,
  useNavigate, useParams, useSearchParams,
} from 'react-router-dom';
import { toast } from 'react-toastify';

import getBrands from '../../../../api/oc_product/brands/gets/getBrands';
import getIndexDetails from '../../../../api/oc_product/product/general/gets/getIndexDetails';
import getLanguages from '../../../../api/oc_product/product/languages/gets/getLanguages';
import getStyle from '../../../../api/oc_product/product/style/gets/getStyle';
import postSearch from '../../../../api/oc_product/search/posts/postSearch';
import { setLanguage } from '../../../../redux/language/Language';
import parseDateString from '../../../../utils/parseDateString';
import ProductInfoHeaderDesktop from './components/ProductInfoHeaderDesktop';
import ProductInfoHeaderMobile from './components/ProductInfoHeaderMobile';
import ProductInfoHeaderTablet from './components/ProductInfoHeaderTablet';
import ScrollableTabs from './components/ScrollableTabs';
import { ProductContext } from './ProductContext';

function Product() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const lastIndexed = location.state?.lastIndexed;
  const [searchParams, setSearchParams] = useSearchParams();
  const selectedSite = useSelector((state) => state.user.loginResponse.selectedSite);
  const languageId = useSelector((state) => state.language.language?.LanguageId);
  const localLanguageId = useSelector((state) => state.user.loginResponse.selectedSite.LanguageId);
  const isDesktop = useMediaQuery('(min-width:1125px)');
  const isTablet = useMediaQuery('(min-width:800px)');

  const { id } = useParams();
  const siteId = useSelector((state) => state.user.loginResponse.selectedSite.SiteId);
  const siteCode = useSelector((state) => state?.user?.loginResponse?.selectedSite?.SiteCode);

  const [isNotFound, setIsNotFound] = useState(false);

  const [variantReRender, setVariantReRender] = useState(false);
  const [enrichmentRerender, setEnrichmentRerender] = useState(false);
  const [productStyleRerender, setProductStyleRerender] = useState(false);
  const [historyReRender, setHistoryReRender] = useState(false);
  const [productStatusRerender, setProductStausRerender] = useState(false);

  const [productSearch, setProductSearch] = useState('');
  const [productDetails, setProductDetails] = useState({});
  const [productIdentifiers, setProductIdentifiers] = useState({});
  const [productStatus, setProductStatus] = useState({});
  const [locations, setLocations] = useState([]);
  const [approved, setApproved] = useState(false);
  const [lastIndexedDate, setlastIndexedDate] = useState(lastIndexed);

  const [languagesOptions, setLanguagesOptions] = useState([]);

  const [loading, setLoading] = useState(true);
  // const [selectedSite.SiteId] = useState(1);
  const [brands, setBrands] = useState([]);
  // params from redux
  const filterQueryParams = useSelector((state) => state.queries.queries);
  const [selectedSearchOption, setSelectedSearchOption] = useState('Plus');
  const getProductData = async () => {
    setLoading(true);

    try {
      const indexRes = await getIndexDetails(id, selectedSite.SiteId);
      const styleRes = await getStyle(id, selectedSite.SiteId);

      const brandsRes = await getBrands();
      const styleDetails = {
        image: styleRes?.data?.Image,
        name: styleRes?.data?.Name,
        brand: styleRes?.data?.Brand,
        colour: styleRes?.data?.Color,
        created: styleRes?.data?.Created,
        lastUpdated: styleRes?.data?.Updated,
        releaseDate: styleRes?.data?.Released,
        productTypes: styleRes?.data?.ProductTypes,
        styleId: styleRes?.data?.StyleId,
        lastIndexed: indexRes.data.LastIndexed !== '' ? indexRes.data.LastIndexed : parseDateString(lastIndexedDate),

      };

      const styleIdentifiers = {
        productId: styleRes?.data?.ProductId,
        plus: styleRes?.data?.Plus,
      };

      const styleStatuses = {
        status: styleRes?.data?.IsActive,
        oms: styleRes?.data?.IsInOMS,
        onSite: styleRes?.data?.IsOnSite,
        fredhopper: styleRes?.data?.IsInFredhopper,
        approved: styleRes?.data?.IsApproved,
      };

      const setterPromises = [
        setProductDetails(styleDetails),
        setProductIdentifiers(styleIdentifiers),
        setProductStatus(styleStatuses),
        setLocations(styleRes?.data?.Locations),
        setApproved(styleRes?.data?.IsApproved),
        setBrands(brandsRes?.data),
      ];

      setIsNotFound(false);

      await Promise.all(setterPromises);
    } catch (error) {
      console.error(error);
      if (error.response?.status === 400) {
        setIsNotFound(true);
      }
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };
  const handleChange = (event) => {
    setSelectedSearchOption(event.target.value);
  };

  useEffect(() => {
    const siteCodeFromParams = searchParams.get('siteCode');
    if (selectedSite && !siteCodeFromParams) { // Set site from redux if there is no query param
      setSearchParams((params) => {
        params.set('siteCode', selectedSite.SiteCode);
        return params;
      });
    }
  }, []);

  useEffect(() => {
    getLanguages(selectedSite.SiteId)
      .then((res) => {
        setLanguagesOptions(res.data);
        dispatch(setLanguage(res.data.find((x) => x.LanguageId === localLanguageId)));
      })
      .then(() => {
        getProductData();
      });
  }, [id, selectedSite.SiteId]);

  useEffect(() => {
    getProductData();
  }, [languageId]);

  useEffect(() => {
    if (productStyleRerender) {
      setlastIndexedDate(lastIndexed);
      getProductData();
      setProductStyleRerender(false);
    }
  }, [productStyleRerender]);

  useEffect(() => {
    if (productStatusRerender) {
      setlastIndexedDate(lastIndexed);
      getProductData();
      setProductStausRerender(false);
    }
  }, [productStatusRerender]);
  const fetchData = () => {
    const selectedId = selectedSearchOption;
    const filterData = {
      siteId,
      siteCode,
    };
    if (selectedId === 'Plus') {
      filterData.plus = [(productSearch)];
    } else {
      filterData.productIds = [Number(productSearch)];
    }
    postSearch(1, 5, filterData)
      .then((res) => {
        if (res.data.Data.length > 0) {
          const products = res.data.Data;

          if (products.length > 1) {
            toast.error(
              <div>
                <p>Multiple Products found with this Plu</p>
              </div>,
            );
            navigate(`/product-info/products?siteCode=${siteCode}&idType=plus&ids=${filterData.plus}`);
          } else {
            const productId = res.data.Data[0]?.ProductId;
            navigate(`/product-info/products/${productId}?siteCode=${siteCode}`);
          }
        } else {
          toast.error('Invalid product ID');
        }
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  };

  return (
    <ProductContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        variantReRender,
        setVariantReRender,
        enrichmentRerender,
        setEnrichmentRerender,
        productStyleRerender,
        setProductStyleRerender,
        productStatusRerender,
        setProductStausRerender,
        historyReRender,
        setHistoryReRender,
        productDetails,
      }}
    >
      <Container maxWidth="xl">
        <nav className="flex flex-col items-center justify-between pb-4 my-4 border-b md:items-center md:flex-row">
          <Link to={`/product-info/products?${filterQueryParams}`}>
            <Button startIcon={<ChevronLeftIcon />}>
              Products Table
            </Button>
          </Link>
          <div className="flex items-center justify-between gap-10">
            <div className="flex items-center gap-6">
              <FormControl variant="standard" size="small">
                <InputLabel id="content-language-select">Content Language</InputLabel>
                <Select
                  labelId="content-language-select"
                  id="content-language-select"
                  label="Content Language"
                  className="w-64"
                  defaultValue={languageId}
                  value={languageId}
                  onChange={(e) => {
                    dispatch(setLanguage(languagesOptions.find((obj) => obj.LanguageId === e.target.value)));
                  }}
                >
                  {languagesOptions?.map((obj) => (
                    <MenuItem value={obj.LanguageId}>{obj.LanguageName}</MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl variant="standard" size="small">
                <Select
                  id="id-type-select"
                  label="PluOrProductId"
                  className="w-64"
                  defaultValue="Plu"
                  value={selectedSearchOption}
                  onChange={handleChange}
                  sx={{
                    marginTop: '1rem',
                    maxWidth: '100px',
                  }}
                >
                  <MenuItem value="Plus">Plu</MenuItem>
                  <MenuItem value="Product">Product</MenuItem>
                </Select>
              </FormControl>
              <TextField
                label={selectedSearchOption === 'Plus' ? 'Plu Id' : 'Product Id'}
                variant="standard"
                size="small"
                type="search"
                onChange={(e) => setProductSearch(e.target.value)}
                onKeyDown={(ev) => {
                  if (ev.key === 'Enter') {
                    fetchData();
                    ev.preventDefault();
                  }
                }}
              />
              <Button
                variant="contained"
                size="small"
                onClick={fetchData}
              >
                Search
              </Button>
            </div>
          </div>
        </nav>
        {!isNotFound ? (
          <>
            <header className="w-full mb-6">
              {isDesktop ? (
                <ProductInfoHeaderDesktop
                  loading={loading}
                  productDetails={productDetails}
                  productIdentifiers={productIdentifiers}
                  productStatus={productStatus}
                  locations={locations}
                  approved={approved}
                  brands={brands}
                />
              ) : isTablet ? (
                <ProductInfoHeaderTablet
                  loading={loading}
                  productDetails={productDetails}
                  productIdentifiers={productIdentifiers}
                  productStatus={productStatus}
                  locations={locations}
                  approved={approved}
                  brands={brands}
                />
              ) : (
                <ProductInfoHeaderMobile
                  loading={loading}
                  productDetails={productDetails}
                  productIdentifiers={productIdentifiers}
                  productStatus={productStatus}
                  locations={locations}
                  approved={approved}
                  brands={brands}
                />
              )}
            </header>
            <ScrollableTabs
              productIdentifiers={productIdentifiers}
              productStatus={productStatus}
            />
          </>
        ) : (
          <div className="flex items-center justify-center w-full h-[calc(100vh-200px)]">
            <h1 className="text-xl font-gothammedium">Product Not Found</h1>
          </div>
        )}
      </Container>
    </ProductContext.Provider>
  );
}

export default Product;
